/* window scroll header fixed Js Start */

//   $(window).scroll(function () {
//     var scroll = $(window).scrollTop();

//     if (scroll > 100) {
//     $("#header").addClass("fixed");
//     } else if(scroll < 60 && scroll > 0) {
//     $("#header").removeClass("fixed");
//     }
//     });




if ($(window).width() > 991) {
    $(document).delegate('.navbar-collapse ul li a', 'click', function () {

        var targetDiv = $(this).attr('href');
        var array = targetDiv.split('#');
        var ab = '#' + array[1];
        if (ab !== "#header") {
            $('html, body').animate({ scrollTop: $(ab).offset().top - 100 }, 'slow');
        }
        return false;
    });
}
else {
    $(document).delegate('.navbar-collapse ul li a', 'click', function () {

        var targetDiv = $(this).attr('href');
        $('html, body').animate({ scrollTop: $(targetDiv).offset().top - 0 }, 'slow');

    });
}



$(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
    } else {
        $('.scrollup').fadeOut();
    }
});


// $(document).delegate('.gh-svg-wrapper', 'click', function () {    
//     $(this).toggleClass('gh-show-nav');
// });

$(document).ready(function () {
    $(document).click(function (event) {
     
        var clickover = $(event.target);
        var _opened = $(".navbar-collapse").hasClass("in");
        if (_opened === true && !clickover.hasClass("navbar-toggle")) {
            $("button.navbar-toggle").click();
        }
    });
});

$(document).delegate('a.scrollTop', 'click', function () {
    // $('a.scrollTop').live('click', function() {
    // $('a.scrollTop').click(function () {
    $("html, body").animate({
        scrollTop: 0
    }, 600);
    return false;
});



/* window scroll header fixed Js Start */




// $("#main-slider").owlCarousel({
//     items: 1,
//     loop: true,
//     autoplay: 3000,
//     dots: true,
//     nav: false,
//     navText: [
//         "<i class='fa fa-angle-left'></i>",
//         "<i class='fa fa-angle-right'></i>"
//     ],
//     autoHeight: false,
//     transitionStyle: "fadeUp",
//     responsiveClass: true,
//     responsive: {
//         0: {
//             nav: false,

//         },
//         768: {
//             nav: false
//         }
//     }
// });












if($('.owl-carousel').length){
$('#news.news-slider2.owl-carousel').owlCarousel({

    items: 4,
    margin: 15,
    /*autoWidth: true,*/
    responsiveClass: true,

    responsive: {
        0: {
            nav: true,
            items: 1,
            dots: true,
            loop: true,
        },
        768: {
            loop: true,
            nav: true,
            dots: true,

        }
    },

});

}



/*
        $(window).scroll(function () {

     var sticky = $('#header'),
    scroll = $(window).scrollTop();

  if (scroll >= 100) sticky.addClass('navbar-fixed-top');
  else sticky.removeClass('navbar-fixed-top');
      
    })
*/




$('.subtabs li a').click(function () {
    var tt = $(this), ttatr = tt.attr('sub-date'), n_tab = tt.closest('.subtabs'), n_content = tt.closest('.subtabs').siblings('.subcontent').find('.subdata');
    n_tab.find('li a').removeClass('active');
    tt.addClass('active');
    n_content.removeClass('active');
    tt.closest('.subtabs').siblings('.subcontent').find(ttatr).addClass('active');
});
function isosection(e) {
    // var tr = $(e).attr("data-target");
    var tt = $(e), tr = tt.attr('data-target'), n_tab = tt.closest('.isosection'),
        n_content = tt.closest('.isosection').siblings('.isocontent').find('.aboutcon');
    n_tab.find('li a').removeClass("active");
    tt.addClass("active");
    n_content.addClass('hidden');
    tt.closest('.isosection').siblings('.isocontent').find(tr).removeClass("hidden");

    // console.log(tt.closest('.isosection').siblings('.isocontent'));
}
